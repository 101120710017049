module.exports = [{
      plugin: require('../../shared/gatsby/plugins/gatsby-plugin-privacy-and-consent/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"release":"e5903679ecb1bd6e48f4f879a8dadb256858a9c2"},
    },{
      plugin: require('../../shared/gatsby/plugins/gatsby-plugin-loadable-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/vercel/path0/node_modules/gatsby-remark-relative-images","id":"46bd1896-257f-5076-b73b-7e4836aab9a7","name":"gatsby-remark-relative-images","version":"2.0.5","modulePath":"/vercel/path0/node_modules/gatsby-remark-relative-images/dist/index.js","module":{"defaultPluginOptions":{"staticFolderName":"static","include":[],"exclude":[]}},"pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateNode"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/vercel/path0/node_modules/gatsby-remark-images","id":"003ec76e-c476-5915-8064-af1d5dce4020","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/vercel/path0/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1152,"formats":["auto","webp","avif"],"breakpoints":[576,768,992,1152]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/vercel/path0/node_modules/gatsby-remark-smartypants","id":"d48194e5-b344-5f22-b93d-76045ee44e88","name":"gatsby-remark-smartypants","version":"5.25.0","modulePath":"/vercel/path0/node_modules/gatsby-remark-smartypants/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0/packages/open","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1152,"formats":["auto","webp","avif"],"breakpoints":[576,768,992,1152]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
